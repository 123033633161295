










































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import * as appRegion from "@/libs/app_region";
import PaymentAuditDialog from "./components/PaymentAuditDialog.vue";
import DeliverDialog from "./components/DeliverDialog.vue";

@Component({
  components: {
    PaymentAuditDialog,
    DeliverDialog
  }
})
export default class MallOrderDetails extends Vue {
  loading: boolean = false;
  id!: any;
  orderData: any = null;
  orderAddress: string = "";
  pagerHeader: any = {
    title: "商城订单详细",
    desc: "商城订单详细",
    breadcrumb: ["订单管理", "商城订单详细"]
  };
  async readerOrderInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/ShoppingOrder/QueryOrderInfo",
        {
          id: this.id
        }
      );
      var data = res.data.result;
      this.orderData = data;
      this.orderAddress = `${
        appRegion.getProvinceByCode(data.addressee.provinceCode).name
      } 
      ${appRegion.getCityByCode(data.addressee.cityCode).name} 
      ${appRegion.getCountyByCode(data.addressee.areaCode).name} `;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.id = this.$route.query.id;
    this.readerOrderInfo();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.id = this.$route.query.id;
      this.readerOrderInfo();
    }
  }
  auditPayment() {
    (this.$refs.auditDialog as any).show(this.id);
  }
  orderDeliver(id: string) {
    (this.$refs.deliverDialog as any).show(id);
  }
  async completeOrder(id: string) {
    var result = await this.$confirm("确认帮助代理确认收货完成该订单吗");
    if (result == "confirm") {
      var loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/ShoppingOrder/Complete",
          {
            id: id
          }
        );
        if (res.data.success) {
          this.$notify.success("订单确认完成成功");
          this.readerOrderInfo();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
